import React from "react"
import { Link } from "gatsby"
import PostCategories from "./PostCategories"
import FeaturedMedia from "./FeaturedMedia"

const PostPreview = ({ post, dynamicImage }) => {
  return (
    <article
      className={`post-${post.databaseId} post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized`}
      id={`post-${post.databaseId}`}
    >
      <div className="row mb-5">
        <div className="col-lg-6 order-last order-lg-first">
          <Link to={post.uri} className="preview-permalink">
            <h2
              className="preview-entry-title"
              dangerouslySetInnerHTML={{ __html: post.title }}
            />
          </Link>
          <PostCategories categories={post.categories} />
        </div>
        <div className="col-lg-6 order-first order-lg-last">
          <Link to={post.uri}>
            {dynamicImage && post.featuredImage ? (
              <img
                src={post.featuredImage.node.sourceUrl}
                alt={post.featuredImage.node.altText}
              />
            ) : (
              <FeaturedMedia image={post.featuredImage} />
            )}
          </Link>
        </div>
      </div>
    </article>
  )
}

export default PostPreview
