import React, { useState } from "react"
import { graphql } from "gatsby"
import { gql, GraphQLClient } from "graphql-request"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import PostPreview from "../../components/PostPreview"
import ArchivePagination from "../../components/ArchivePagination"
import SearchAndFilter from "../../components/SearchAndFilter"

const ArchivePost = (props) => {
  const {
    data: {
      allWpPost: { nodes, pageInfo },
      allWpTermNode,
      page,
    },
    pageContext: {
      archivePath,
      taxPluralNames,
      siteMetadata: { wpgraphqlUrl },
    },
  } = props

  const [postsResult, setPostsResult] = useState()

  const getPosts = async (searchValue, taxonomyFilter) => {
    const mutation = gql`
      query GET_POSTS(
        $search: String
        $taxArray: [RootQueryToPostConnectionWhereArgsTaxArray]
      ) {
        posts(
          where: {
            search: $search
            taxQuery: { relation: AND, taxArray: $taxArray }
          }
        ) {
          nodes {
            uri
            title
            databaseId
            excerpt
            date
            featuredImage {
              node {
                sourceUrl
                altText
              }
            }
            categories {
              nodes {
                name
                slug
                uri
              }
            }
          }
        }
      }
    `

    const graphQLClient = new GraphQLClient(wpgraphqlUrl, {})

    const taxArray = Object.entries(taxonomyFilter).reduce(
      (result, [key, value]) => {
        if (value !== "0") {
          result.push({
            terms: value,
            taxonomy: key.toUpperCase(),
            operator: "IN",
            field: "TAXONOMY_ID",
          })
        }
        return result
      },
      []
    )

    if (!searchValue && taxArray?.length === 0) {
      setPostsResult(null)
      return
    }

    const postData = await graphQLClient.request(mutation, {
      search: searchValue,
      taxArray: !taxonomyFilter && taxArray?.length === 0 ? null : taxArray,
    })

    setPostsResult(postData)
  }

  return (
    <Layout bodyClass="archive">
      <Seo seoData={page.seo} uri={page.uri} />

      <section id="blog-header" className="dark-bg">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>News, tricks and advice on all things commerce.</h1>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <SearchAndFilter
            sendQuery={getPosts}
            filters={allWpTermNode.group}
            taxPluralNames={taxPluralNames}
          />
          <div id="loopContainer">
            {postsResult?.posts &&
              postsResult.posts.nodes.map((post, index) => {
                return (
                  <PostPreview
                    key={index}
                    post={post}
                    dynamicImage
                    isLast={index === nodes.length - 1}
                  />
                )
              })}
            {!postsResult?.posts &&
              nodes &&
              nodes.map((post, index) => {
                return (
                  <PostPreview
                    key={index}
                    post={post}
                    isLast={index === nodes.length - 1}
                  />
                )
              })}
          </div>
        </div>

        {!postsResult?.posts && nodes && (
          <ArchivePagination {...pageInfo} archivePath={archivePath} />
        )}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ArchivePage(
    $offset: Int!
    $perPage: Int!
    $termTaxonomyId: Int
    $taxSingleNames: [String]
  ) {
    allWpPost(
      limit: $perPage
      skip: $offset
      filter: {
        terms: {
          nodes: { elemMatch: { termTaxonomyId: { eq: $termTaxonomyId } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...PostPreviewContent
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
    allWpTermNode(filter: { nodeType: { in: $taxSingleNames } }) {
      group(field: nodeType) {
        name: fieldValue
        items: nodes {
          databaseId
          name
          count
        }
      }
    }
    page: wpPage(isPostsPage: { eq: true }) {
      uri
      seo {
        ...SeoData
      }
    }
  }
`

export default ArchivePost
